<template>
  <div>
    <Form ref="contactData" :rules="rules" :model="contactData" label-position="left">
      <Row :gutter="8">
        <i-col :span="colSize">
          <FormItem prop="department">
            <Input :size="size" v-model="contactData.department" placeholder="部门"/>
          </FormItem>
        </i-col>
        <i-col :span="colSize">
          <FormItem prop="position">
            <Input  :size="size" v-model="contactData.position" placeholder="职务"/>
          </FormItem>
        </i-col>
        <i-col :span="colSize">
          <FormItem prop="name">
            <Input  :size="size" v-model="contactData.name" placeholder="联系人姓名"/>
          </FormItem>
        </i-col>
        <i-col :span="colSize">
          <FormItem prop="phone">
            <Input  :size="size" v-model="contactData.phone" placeholder="联系电话"/>
          </FormItem>
        </i-col>
      </Row>
    </Form>
  </div>
</template>

<script>
import { addContact } from '@/api/crm/advertiser'
export default {
  props: {
    size: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      contactData: {
        advertiserId: '',
        name: '',
        position: '',
        department: '',
        phone: '',
        userId: this.$store.getters.token.userInfo.userId
      },
      rules: {
        phone: [
          { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: ' ' }
        ]
      }
    }
  },
  methods: {
    handleAddContact (advertiserId) {
      this.contactData.advertiserId = advertiserId
      const reg = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/
      if (this.contactData.name.trim() !== '' && this.contactData.position.trim() !== '' && this.contactData.department.trim() !== '' && reg.test(this.contactData.phone)) {
        addContact(this.contactData)
      } else if (this.contactData.name.trim() === '' && this.contactData.position.trim() === '' && this.contactData.department.trim() === '' && this.contactData.phone.trim() === '') {
        this.$Notice.warning({ desc: '联系人信息未填写，请稍后在客户详情处添加！' })
      } else {
        this.$Notice.error({ desc: '联系人信息未正确填写，添加失败，请稍后在客户详情处添加！' })
      }
    },
    handleReset () {
      this.$refs.contactData.resetFields()
    }
  },
  computed: {
    colSize () {
      if (this.size === 'small') {
        return 12
      } else {
        return 6
      }
    }
  }
}
</script>
