<template>
  <div>
    <Form
      ref="customerData"
      id="customerData"
      :model="customerData"
      :rules="ruleValidate"
      label-position="top"
    >
        <FormItem label="公司名称" prop="name">
          <Row :gutter="8">
            <i-col span="18">
              <Input
                size="small"
                v-model="customerData.name"
                placeholder="请填写公司名称"
              />
            </i-col>
            <i-col span="6">
              <Button
                size="small"
                type="success"
                :disabled="!customerData.name"
                @click="searchConflictPage"
                >冲突预测</Button
              >
            </i-col>
          </Row>
        </FormItem>

      <FormItem label="统一信用代码/组织机构代码/纳税人识别号" prop="orgCode">
        <Row :gutter="8">
          <i-col span="18">
            <Input v-model="customerData.orgCode" disabled size="small" placeholder="格式：91510100MA62L2A037、MA62L2A03、510108600454493" />
          </i-col>
          <i-col span="6">
            <Button size="small" type="success" @click="writeOrgCode"
              >自动录入</Button
            >
          </i-col>
        </Row>
      </FormItem>

      <FormItem label="客户类型" class="ivu-form-item-required">
        <RadioGroup size="small" v-model="customerData.type" type="button">
          <Radio
            v-for="(item, index) in Object.keys(AdvertiserType)"
            :label="item"
            :key="index"
            >{{ AdvertiserType[item] }}</Radio
          >
        </RadioGroup>
      </FormItem>

      <FormItem label="客户地址" prop="cityCode">
        <Row :gutter="4">
          <i-col span="8">
            <FormItem prop="provinceCode">
              <Select
                size="small"
                v-model="customerData.provinceCode"
                clearable
                placeholder="省"
              >
                <Option
                  v-for="item in provinceList"
                  :value="item.code"
                  :key="item.code"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem prop="cityCode">
              <Select
                size="small"
                v-model="customerData.cityCode"
                clearable
                placeholder="市"
              >
                <Option
                  v-for="item in cityList"
                  :value="item.code"
                  :key="item.code"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem prop="areaCode">
              <Select
                size="small"
                v-model="customerData.areaCode"
                clearable
                placeholder="区"
              >
                <Option
                  v-for="item in regionList"
                  :value="item.code"
                  :key="item.code"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </i-col>
        </Row>
      </FormItem>
      <FormItem prop="address">
        <i-input
          size="small"
          v-model.trim="customerData.address"
          placeholder="输入详细地址"
        ></i-input>
      </FormItem>
      <FormItem label="联系电话">
        <Input
          size="small"
          v-model="customerData.tel"
          placeholder="请填写公司联系电话"
        />
      </FormItem>

      <FormItem label="公司类型"  class="ivu-form-item-required">
        <RadioGroup
          size="small"
          v-model="customerData.companyType"
          type="button"
        >
          <Radio
            v-for="(item, index) in companyTypes"
            :label="item.id"
            :key="index"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
      </FormItem>
      <FormItem label="客户来源">
        <RadioGroup size="small" v-model="customerData.adFrom" type="button">
          <Radio
            v-for="(item, index) in adFroms"
            :label="item.id"
            :key="index"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
      </FormItem>
      <FormItem label="客户备注" prop="description">
        <Input
          size="small"
          v-model="customerData.description"
          placeholder="请填写客户备注信息"
        />
      </FormItem>

      <FormItem
        label="报备品牌"
        prop="advertiserBrandJson"
        class="ivu-form-item-required"
      >
        <Tag
          v-for="(item, index) in brandList"
          :key="index"
          color="black"
          :name="index"
          closable
          @on-close="handleRemove"
          >{{ item.platformBrandName? item.platformBrandName : item.name + "（" + item.industryName + "）" }}</Tag
        >
        <a type="info" size="small" @click="handleAddBrand">添加品牌</a>
      </FormItem>

      <FormItem label="联系人">
        <add-contact ref="addContact" :size="'small'"></add-contact>
      </FormItem>

      <FormItem label="附件上传">
        <fileUpload ref="fileUpload" :size="'small'"></fileUpload>
      </FormItem>

      <FormItem class="text-right">
        <Button
          class="btn-black m-l-8"
          size="small"
          @click="handleReset('customerData')"
          >重置</Button
        >
        <Button
          class="m-l-8"
          type="success"
          size="small"
          @click="handleSubmit('customerData')"
          :loading="allowSubmit"
          >{{ isAuth("Advertiser_Manage_View") ? "添加" : "提交报备" }}</Button
        >
      </FormItem>
    </Form>

    <AddBrand ref="addBrandComponet" @on-success="ConfirmAddBrand"/>

    <Modal
      v-model="customerConflictModal"
      title="客户冲突预查询"
      width="800"
      :footer-hide="true"
    >
      <h2
        v-if="conflictdata.length === 0"
        class="text-green text-center m-t-50 p-b-50"
      >
        当前未检测到可能冲突的客户信息
      </h2>
      <Card v-else>
        <span slot="title">当前可能冲突的客户</span>
        <Table
          stripe
          :columns="conflictcolumndata"
          :data="conflictdata"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="conflictTotal"
            :page-size="customerQuery.pageSize"
            show-total
            show-elevator
            :current="customerQuery.pageNumber"
            @on-change="conflictChangePage"
          ></Page>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import FileUpload from '@/components/advertiser/CustomerFileUpload'
import AddContact from '@/components/advertiser/AddContact'
import AddBrand from '@/components/advertiser/AddBrand'

import { getAvailabLeadType } from '@/api/os/publishercompany'
import {
  addbypublisher,
  addBySeller,
  getAdvertiserPage,
  getadvertiserdict
} from '@/api/crm/advertiser'
import {
  getProvinceList,
  getCityListByProvinceCode,
  getRegionListByCityCode
} from '@/api/sys/provincecityregion'
import { queryorgcodebycompanyfullname } from '@/api/scp/invoice'

import { getTimeDifference } from '@/utils/dateFormat'
import {
  formatCustomerStatus,
  formatCustomerDifference
} from '@/utils/tagStatus'
export default {
  components: {
    FileUpload,
    AddContact,
    AddBrand
  },
  data () {
    return {
      // 客户冲突预测
      customerConflictModal: false,
      conflictTotal: 0,
      customerQuery: {
        // publisherId: this.$store.getters.token.userInfo.publisherId,
        pageNumber: 1,
        pageSize: 6,
        keyword: ''
      },
      conflictcolumndata: [
        { title: '客户名称', key: 'name' },
        { title: '报备品牌', key: 'brands' },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(
              h,
              params.row.companyAdvertiserStatus,
              params.row.companyAdvertiserStatusName
            )
          }
        },
        {
          title: '保护倒计时',
          key: 'difference',
          align: 'center',
          render: (h, params) => {
            return formatCustomerDifference(
              h,
              getTimeDifference(Date.now(), params.row.overdueTime)
            )
          }
        }
      ],
      conflictdata: [],

      publisherId: this.$store.getters.token.userInfo.publisherId,
      provinceList: [],
      AdvertiserType: [],
      companyTypes: [],
      adFroms: [],
      cityList: [],
      regionList: [],
      brandList: [],

      customerData: {
        publisherId: '',
        userId: '',
        type: '1',
        name: '',
        orgCode: '',
        tel: '',
        areaCode: '',
        cityCode: '',
        provinceCode: '',
        address: '',
        companyType: '',
        adFrom: '',
        description: '',
        advertiserBrandJson: '',
        advertiserType: null
      },
      ruleValidate: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        cityCode: [{ required: true, message: ' ' }],
        orgCode: [
          {
            required: true,
            message: ' ',
            trigger: 'blur'
          },
          {
            pattern:
              /^([a-zA-Z0-9]{2}[0-9]{6}[a-zA-Z0-9]{10}|[a-zA-Z0-9]{9}|[a-zA-Z0-9]{15})$/,
            message: ' '
          }
        ]
      },
      allowSubmit: false,
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx']
    }
  },
  created () {
    this.getAdvertiserdict()
    this.getAdvertiserTypes()
    this.getProvinceList()
  },
  methods: {
    searchConflictPage () {
      this.customerQuery.pageNumber = 1
      this.getConflictPageData()
    },
    conflictChangePage (currentPage) {
      this.customerQuery.pageNumber = currentPage
      this.getConflictPageData()
    },
    getConflictPageData () {
      if (this.customerData.name) {
        this.customerQuery.keyword = this.customerData.orgCode || this.customerData.name
        getAdvertiserPage(this.customerQuery).then((response) => {
          this.conflictTotal = response.totalRow
          this.conflictdata = response.list
          this.customerQuery.pageNumber = response.pageNumber
          this.customerConflictModal = true
        })
      } else {
        this.conflictTotal = 0
        this.conflictdata = []
        this.customerQuery.pageNumber = 1
      }
    },
    writeOrgCode () {
      if (this.customerData.name) {
        queryorgcodebycompanyfullname({ companyName: this.customerData.name }).then(res => {
          if (res) {
            this.customerData.orgCode = res.organizationCode
          } else {
            this.$Notice.error({ desc: '未检索到社会统一信息代码，请确认公司名称是否正确或手动输入社会统一信息代码' })
          }
        })
      } else {
        this.$Notice.info({ desc: '您未输入公司名称！' })
      }
    },

    getAdvertiserTypes () {
      const query = {
        publisherId: this.publisherId,
        companyId: this.$store.getters.token.userInfo.companyId
      }

      getAvailabLeadType(query).then((res) => {
        if (res.errcode !== 0 && res.errcode !== undefined) {
          this.$Notice.error({ desc: '获取客户类型出错！' })
        } else {
          this.AdvertiserType = res
        }
      })
    },
    getAdvertiserdict () {
      getadvertiserdict({}).then((res) => {
        if (res.errcode !== 0 && res.errcode !== undefined) {
          this.$Notice.error({ desc: '获取客户字典出错！' })
        } else {
          this.adFroms = res.adFrom
          this.companyTypes = res.companyType
          if (this.companyTypes.length) {
            this.customerData.companyType = this.companyTypes[0].id
          }
        }
      })
    },
    getProvinceList () {
      getProvinceList().then((res) => {
        this.provinceList = res
      })
    },
    getCityList () {
      const data = {
        provinceCode: this.customerData.provinceCode
      }
      getCityListByProvinceCode(data).then((res) => {
        this.cityList = res
      })
    },
    getRegionList () {
      const data = {
        cityCode: this.customerData.cityCode
      }
      getRegionListByCityCode(data).then((res) => {
        this.regionList = res
      })
    },
    handleSubmit (dataname) {
      const that = this
      that.$refs[dataname].validate((valid) => {
        if (valid) {
          if (that.customerData.advertiserBrandJson) {
            that.customerData.publisherId =
              that.$store.getters.token.userInfo.publisherId
            that.customerData.userId =
              that.$store.getters.token.userInfo.userId

            that.customerData.sign = 1

            that.allowSubmit = true

            if (this.isAuth('Advertiser_Manage_View')) {
              addbypublisher(this.customerData)
                .then((res) => {
                  if (res && !res.errcode) {
                    this.$Notice.success({ desc: '成功创建客户！' })
                    this.$refs.fileUpload.handleUpload(res)
                    this.$refs.addContact.handleAddContact(res)
                    // 更新列表
                    this.$store.commit('set_advertiser_update', new Date())
                    this.handleReset('customerData')
                  }
                  this.allowSubmit = false
                })
                .catch(() => {
                  this.allowSubmit = false
                })
            } else {
              addBySeller(this.customerData)
                .then((res) => {
                  if (res && !res.errcode) {
                    this.$Notice.success({ desc: '成功创建客户！' })
                    this.$refs.fileUpload.handleUpload(res)
                    this.$refs.addContact.handleAddContact(res)
                    // 更新列表
                    this.$store.commit('set_advertiser_update', new Date())
                    this.handleReset('customerData')
                  }
                  this.allowSubmit = false
                })
                .catch(() => {
                  this.allowSubmit = false
                })
            }
          } else {
            that.$Notice.info({ desc: '请先完善客户品牌信息！' })
          }
        } else {
          this.allowSubmit = false
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields()
      this.brandList = []
      this.customerData.advertiserBrandJson = ''
      this.$refs.fileUpload.handleReset()
      this.$refs.addContact.handleReset()
    },
    handleRemove (event, name) {
      this.brandList.splice(name, 1)
      this.reloadBrand()
    },
    handleAddBrand () {
      this.$refs.addBrandComponet.showModal()
    },
    ConfirmAddBrand (brandBean) {
      this.brandList.push(brandBean)
      this.reloadBrand()
    },
    reloadBrand () {
      if (this.brandList.length > 0) {
        this.customerData.advertiserBrandJson = JSON.stringify(this.brandList)
      } else {
        this.customerData.advertiserBrandJson = ''
      }
    },
    handleAddContact () {
      this.AddContactModal = true
    }
  },
  watch: {
    'customerData.provinceCode' (val) {
      if (val) {
        this.getCityList()
      } else {
        this.customerData.cityCode = ''
        this.customerData.areaCode = ''

        this.cityList = []
        this.regionList = []
      }
    },
    'customerData.cityCode' (val) {
      if (val) {
        this.getRegionList()
      } else {
        this.customerData.areaCode = ''
        this.regionList = []
      }
    }
  }
}
</script>
